* {
  box-sizing: border-box;
  font-family: Manrope, helvetica, sans-serif;
}

.App {
  text-align: center;
}

.App {
  background-color: #d5e0fc;
  /*background-image: linear-gradient(#d5e0fc, #0080f9);*/ /* #282c34 */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  font-family: Manrope, helvetica, sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: 'Manrope-Bold', sans-serif;
  font-style: bold;
  font-weight: 800;
  src: local('Manrope-Bold'), url('fonts/Manrope-Bold.ttf');
}
@font-face {
  font-family: 'Manrope';
  font-weight: 400;
  src: local('Manrope-Regular'), url('fonts/Manrope-Regular.ttf');
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
